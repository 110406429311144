var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{attrs:{"flat":"","outlined":""}},[_c('v-card-title',[_c('v-row',{staticClass:"py-1 px-3 grey lighten-3",attrs:{"align":"center","no-gutters":""}},[_c('h4',{staticClass:"grey--text text--darken-2 font-weight-medium"},[_vm._v("Sedes")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"success","small":"","depressed":""},on:{"click":function($event){_vm.dialog = true}}},[_vm._v(" "+_vm._s(_vm.lang.create)+" ")])],1)],1),_c('v-card-text',[_c('v-row',{attrs:{"no-gutters":"","dense":"","justify":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.headquarters.items,"hide-default-footer":"","disable-sort":""},on:{"click:row":_vm.rowClick},scopedSlots:_vm._u([{key:"header.name",fn:function(ref){
var header = ref.header;
return [_c('div',[_c('v-row',{attrs:{"justify":"center","align":"center","no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('h3',{staticClass:"grey lighten-2 py-2 font-weight-medium"},[_vm._v(_vm._s(header.text))])])],1)],1)]}},{key:"item.createdBy",fn:function(ref){
var item = ref.item;
return [(item.createdBy)?_c('v-chip',{attrs:{"color":"primary","small":""},on:{"click":function($event){return _vm.$router.push('/clients/' + item.createdBy.id)}}},[_vm._v(" "+_vm._s(item.createdBy.email)+" ")]):_vm._e()]}},{key:"header.email",fn:function(ref){
var header = ref.header;
return [_c('v-row',{attrs:{"justify":"center","align":"center","no-gutters":"","dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('h3',{staticClass:"grey lighten-2 py-2 font-weight-medium"},[_vm._v(_vm._s(header.text))])])],1)]}},{key:"header.createdAt",fn:function(ref){
var header = ref.header;
return [_c('v-row',{attrs:{"justify":"center","align":"center","no-gutters":"","dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('h3',{staticClass:"grey lighten-2 py-2 font-weight-medium"},[_vm._v(_vm._s(header.text))])])],1)]}},{key:"item.enabled",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":item.enable ? 'success' : 'error',"small":"","outlined":""}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(_vm._s(item.enable ? 'mdi-check' : 'mdi-window-close'))]),_vm._v(" "+_vm._s(item.enable ? _vm.lang.active : _vm.lang.inactive)+" ")],1)]}},{key:"header.createdBy.email",fn:function(ref){
var header = ref.header;
return [_c('v-row',{attrs:{"justify":"center","align":"center","no-gutters":"","dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('h3',{staticClass:"grey lighten-2 py-2 font-weight-medium"},[_vm._v(_vm._s(header.text))])])],1)]}},{key:"header.enabled",fn:function(ref){
var header = ref.header;
return [_c('v-row',{attrs:{"justify":"center","align":"center","no-gutters":"","dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('h3',{staticClass:"grey lighten-2 py-2 font-weight-medium"},[_vm._v(_vm._s(header.text))])])],1)]}}])})],1)],1)],1)],1)],1)],1),_c('v-dialog',{attrs:{"width":"800px","persistent":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('CreateHeadquarterDialog',{on:{"refresh":_vm.refresh,"close":function($event){_vm.dialog = false}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }