






























































































import HeadquarterService from "@/services/HeadquarterService"
import {MultipleItem} from "@/handlers/interfaces/ContentUI"
import {Vue, Component} from 'vue-property-decorator'
import {getModule} from "vuex-module-decorators"
import Headquarter from "@/models/Headquarter"
import LangModule from "@/store/LangModule"
import Handler from "@/handlers/Handler"
import CreateHeadquarterDialog from "@/components/dialog/CreateHeadquarterDialog.vue";

@Component({
    components: {CreateHeadquarterDialog}
})
export default class HeadquartersView extends Vue {

    loading: boolean = false
    dialog: boolean = false
    get lang() { return getModule(LangModule).lang }
    headquarters: MultipleItem<Headquarter> = { items: [], totalItems: 0 }

    page: number = 0
    size: number = 10

    headers = [
        {align: "center", text: this.lang.name, value: "name", width: "auto"},
        {align: "center", text: this.lang.commercialName, value: "name", width: "auto"},
        {align: "center", text: this.lang.email, value: "email", width: "auto"},
        {align: "center", text: this.lang.date, value: "createdAt", width: "auto"},
        {align: "center", text: this.lang.createdBy, value: "createdBy.email", width: "auto"},
        {align: "center", text: this.lang.enabled, value: "enabled", width: "auto"},
    ]

    created() {
        this.refresh()
    }

    refresh() {
        this.getHeadquarters()
    }

    rowClick(headquarter: Headquarter) {
        this.$router.push({path: "/headquarters/" + headquarter.id})
    }

    async getHeadquarters() {
        await Handler.getItems(this, this.headquarters, () =>
            HeadquarterService.findAll(this.page, this.size)
        )
    }

}
